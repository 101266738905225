/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    h1: "h1"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox.entramado.net/wp-content/uploads/sites/6/Its_free.png"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 68.35443037974683%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAABz0lEQVQ4y5VTveuxURj2LfItipgUf4LFQgwGg3yEsEsUWTCITRYWm8VH2WwyUlaRScpmZZFR169z16PneX/et9epq+c55zpd577OuW6RSCQCg0QiAffPx9/W/wERxGIxTex2O3q9HlarFSaTCc2/FuXEnE4nbrcb+ON4PMJoNIJ/qEKhgNlshsFggFKphEqlEgpypy8WC1wuF5xOJ4HoeDx+C8rlcmQyGdTrdeRyORSLRdRqtc+WW60WnTqdTqnS4XCIzWaDarX63qzRaJDNZpFKpWC1WhGLxTAajWj9l2Wv14t2u43n84n7/Y7tdovr9YrlckkW+aKcgF6vh8vl+nyHjGBCbDweD8JutyPLFosFMpkM8XgcgUAA0WgUfr8fkUiECkkmk+TubVkqldKk2WyS4Pl8RqfTQblcRigUIs7hcGAwGKBQKNB99/t92lOpVCgZOp3ud2x8Ph8Jvl4vHA4HzGYzdLtd4kwmE9xuN2w2G1UVDoeRSCQQDAaRTqeJJ0H29MyuVqulhXw+j/1+j/V6TbEplUrfBZvlSq1WC7LWaDQwn8/JrsfjeYeb49n3Twhiw4EjWLXMEmeTz/1X630S5bqCve7XvfwJX1QkwA+ldrZhZKb3XQAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"meme de it&#39;s free\"\n        title=\"meme de it&#39;s free\"\n        src=\"/static/b317149cf7e24a3af4e836201cc74aab/5a46d/Its_free-300x206.png\"\n        srcset=\"/static/b317149cf7e24a3af4e836201cc74aab/c26ae/Its_free-300x206.png 158w,\n/static/b317149cf7e24a3af4e836201cc74aab/5a46d/Its_free-300x206.png 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "Doy la bienvenida al la blogosfera a un buen amigo y antiguo compañero de batallas contra el compilador, se esconde bajo el seudónimo de el Oráculo de las muestras y descubriréis en sus entradas llenas de humor un aspecto sobre el que se habla poco de la electrónica, ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/?s=muestras"
  }, "aunque alguna vez lo he comentado"), ", el de las muestras gratuitas de productos, lo mejor para hacer nuestros prototipos. Sin más preámbulo os dejo con el blog:"), "\n", React.createElement(_components.h1, null, React.createElement(_components.a, {
    href: "http://muestraseverywhere.blogspot.com.es/"
  }, "Muestras Everywhere")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
